import React from 'react'
import './Works.css'
import Upwork from '../../img/Upwork.png'
import Fiverr from '../../img/fiverr.png'
import Amazon from '../../img/amazon.png'
import Shopify from '../../img/Shopify.png'
import Facebook from '../../img/Facebook.png'
import { themeContext } from '../../Context'
import { useContext } from 'react'
import { motion } from "framer-motion";
const Works = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const transition = {duration: 3.5, type: 'spring'}
  return (
    <div className='works'>
      {/* Left side */}
      <div className='awesome'>
         <span style={{ color: darkMode ? "white" : "" }}>Works for All these</span>
         <span>Brands & Clients</span>
         <span>
         I HAVE EXPERIENCE IN WEB DESIGNING 
         <br/> 
         DEVELOPEMENT HTML CSS React Js.
         <br/>
         TARGET TO GET EXPERTISE IN 
         <br/>
         WEB DESIGNING AND DEVELOPMENT. 
         </span>
         <button className='button s-button'>Hire me</button>
         <div className='blur s-blur1' style={{background: '#ABF1FF94'}}></div>
        </div>
        {/* Right Side */}
        <div className='w-right'>
            <motion.div 
                initial={{ rotate: 45 }}
                whileInView={{ rotate: 0 }}
                viewport={{ margin: "-40px" }}
                transition={transition}
                className='w-mainCircle'>
                <div className='w-secCircle'>
                    <img src={Upwork} alt=''/>
                </div>
                <div className='w-secCircle'>
                    <img src={Fiverr} alt=''/>
                </div>
                <div className='w-secCircle'>
                    <img src={Amazon} alt=''/>
                </div>
                <div className='w-secCircle'>
                    <img src={Shopify} alt=''/>
                </div>
                <div className='w-secCircle'>
                    <img src={Facebook} alt=''/>
                </div>
            </motion.div>
            {/* background Circles */}
            <div className='w-backCircle blueCircle'></div>
            <div className='w-backCircle yellowCircle'></div>
        </div>
    </div>
  )
}

export default Works