import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import Resume from './Resume.pdf'
import { themeContext } from '../../Context'
import { useContext } from 'react'
import {motion} from 'framer-motion'
const Services = () => {
  const transition = {duration: 1, type: 'spring'}
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='services'>
        {/* Left side */}
        <div className='awesome'>
         <span  style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
         <span>Services</span>
         <span>
         I HAVE EXPERIENCE IN WEB DESIGNING DEVELOPEMENT HTML CSS <br/> 
         React Js. 
         </span>
         <a href={Resume} download>
         <button className='button s-button'>Download Cv</button>
         </a>
         <div className='blur s-blur1' style={{background: '#ABF1FF94'}}></div>
        </div>
        {/* Right side */}
        <div className='cards'>
         <motion.div 
          initial={{ left: "25rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
         >
            <Card
              emoji = {HeartEmoji}
              heading = {'Design'}
              detail = {'Figma, Sketch, Photoshop, Adobe, Adobe Xd'}  
            />
         </motion.div>
         <motion.div 
         initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
         >
          <Card
            emoji = {Glasses}
            heading = {'Developer'}
            detail = {'Html, Css, JavaScript, React.js, Designing'}
          />
         </motion.div>
         <motion.div 
         initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
         >
          <Card
            emoji = {Humble}
            heading = {'UI/UX'}
            detail = {'Adobe, Photoshop, LightRoom, Adobe Xd'}
          />
         </motion.div>
         <div className='blur s-blur2' style={{background: 'var(--purple)'}}></div>
        </div>
    </div>
  )
}

export default Services